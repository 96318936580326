var domain = function(param) {
  return param;
}
export default {
  "/gateway": domain({//新网关
    test: "https://liefeng-gateway.test.gongxiangplat.com",
    prod: "https://api.gateway.prod.liefengtech.com",
  }),
  "/o2o": domain({
    test: "http://test.o2o-gateway.dubbo.liefengtech.com/o2o", //测试
    prod: "https://gateway.prod.liefengtech.com/o2o", //生产
  }),
  "/gx": domain({
    test: "https://liefeng-gateway.test.gongxiangplat.com/apps/org/org", //测试
    prod: "https://api.gateway.prod.liefengtech.com/apps/org/org", //生产
  }),
  "/oldpeople": domain({
    test: "https://liefeng-gateway.test.gongxiangplat.com/apps/oldpeople",
    prod: "https://api.gateway.prod.liefengtech.com/apps/oldpeople",
  }),
  "/old": domain({
    test: "https://liefeng-gateway.test.gongxiangplat.com/apps/oldpeople",
    prod: "https://api.gateway.prod.liefengtech.com/apps/oldpeople",
  }),
  "/syaa": domain({
    test: "https://liefeng-gateway.test.gongxiangplat.com/apps/sy-user/sy-user",
    prod: "https://api.gateway.prod.liefengtech.com/apps/sy-user/sy-user",
  }),
  "/sendmsg": domain({
    test: "https://liefeng-gateway.test.gongxiangplat.com/apps/sy-chat/sy-chat",
    prod: "https://api.gateway.prod.liefengtech.com/apps/sy-chat/sy-chat",
  }),
  "/verifyapi": domain({
    test: "https://liefeng-gateway.test.gongxiangplat.com/apps/sy-top/sy-top",
    prod: "https://api.gateway.prod.liefengtech.com/apps/sy-top/sy-top",
  }),
  "/baseparty": domain({
    test: "http://test.property.dubbo.liefengtech.com",
    prod: "https://prod.property.dubbo.liefengtech.com",
  }),
  "/datamsg": domain({
    test: "https://liefeng-gateway.test.gongxiangplat.com/apps/oldpeople",
    prod: "https://api.gateway.prod.liefengtech.com/apps/oldpeople",
  }),
  "/oss": domain({
    test: "http://liefeng.oss-cn-shenzhen.aliyuncs.com",
    prod: "https://liefeng.oss-cn-shenzhen.aliyuncs.com",
  }), // 字典数据

  "/voteapi": domain({
    test: "https://liefeng-gateway.test.gongxiangplat.com/apps/oldpeople",
    prod: "https://api.gateway.prod.liefengtech.com/apps/oldpeople",
  }),
  "/basic": domain({
    test: "https://liefeng-gateway.test.gongxiangplat.com/apps/basic",
    prod: "https://api.gateway.prod.liefengtech.com/apps/basic",
  }),
  "/statistic": domain({
    test: "https://liefeng-gateway.test.gongxiangplat.com/apps/sy-manage/sy-manage",
    prod: "https://api.gateway.prod.liefengtech.com/apps/sy-manage/sy-manage",
  }),
  "/lfcommon": domain({
    test: "https://liefeng-gateway.test.gongxiangplat.com/apps/lf-common/lf-common",
    prod: "https://api.gateway.prod.liefengtech.com/apps/lf-common/lf-common",
  })
  ,
  "/chat": domain({
    test: "https://liefeng-gateway.test.gongxiangplat.com/apps/sy-chat/chat",
    prod: "https://api.gateway.prod.liefengtech.com/apps/sy-chat/chat",
  })
  ,
  "/wsschat": domain({
    test: "wss://api.sy-chat.test.gongxiangplat.com/chat",
    prod: "wss://api.sy-chat.prod.gongxiangplat.com/chat",
  })
}
